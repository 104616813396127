.site-footer {
    background-color:$darkest;
    color:#FFF;
    padding:175px 132px 150px;

    p, li, a {
        color:#FFF;
    }

    h2 {
        color:#FFF;
        font-family:$playfair;
        font-size: 24px;
        margin-bottom: 44px;
    }

    .footer-columns {
        display:flex;



        .left-col,
        .right-col {
            width:50%;
        }

        .left-col {
            padding-right:30px;
            display:flex;
            flex-direction:column;

            .footer-logo {
                max-width: 245px;
                margin-bottom:45px;

                img {
                    width:100%;
                    max-width:100%;
                }
                
            }

            p.desc-text {
                font-size: 24px;
                line-height:39px;
            }

            .footer-social-links {
                display:flex;
                margin-top:auto;

                a {
                    color:rgba(255, 255, 255, 0.5);

                    + a {
                        margin-left:30px;
                    }

                    i {
                        color:rgba(255, 255, 255, 0.5);
                        font-size:20px;
                    }
                }
            }
        }
        .right-col {
            padding-left:30px;

            .footer-contact-info {
                margin-bottom:32px;

                .footer-contact-item {
                    font-size:14px;
                    line-height:24px;
                    letter-spacing:2px;
                    text-transform:uppercase;
                    color: $red;

                    a {
                        color:$red;
                    }
                }
            }

            ul {
                display:flex;
                margin-left:-5px;

                li {
                    a {
                        color:rgba(255, 255, 255, 0.5);
                        font-size:18px;
                        line-height:26px;
                        padding:0 5px;
                    }  

                    + li {
                        margin-left:42px;
                    }
                }
            }

            .footer-address {
                margin-top:36px;
                
                a {
                    color: rgba(255, 255, 255, 0.25);
                    text-transform:uppercase;
                    font-size:14px;
                    letter-spacing:2px;
                }
            }
            
        }
    }

    @media screen and (max-width:1370px) {
        padding-left:0;
        padding-right:0;
        
        .footer-columns {
            margin:0 auto;
        }
    }

    @media screen and (max-width:1023px) {
        padding-top:70px;
        padding-bottom:40px;

        .footer-columns {
            flex-direction:column;

            .left-col,
            .right-col {
                padding-left:0;
                padding-right:0;
                width:100%;
            }

            .left-col {
                display:none;
            }
        }
    }
}