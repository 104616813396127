.horizontal-carousel-wrapper {
    width: calc(50vw + (#{$containerLargest}/2) - 10px);
    max-width:calc(100vw - 30px);
    margin-left:auto;
    padding:50px 0 50px 60px;
    position:relative;

    &.animated {
        background-color:#F8F8F8;
    }

    .horizontal-carousel {
        .horizontal-carousel-item  {
            background-color:#FFF;
            display:flex;
            flex-direction:column;
            padding:94px 40px 70px;
            text-align:center;
            width:424px;
            max-width:424px;
            margin-right:34px;

            .pdf-lightbox {
        
                height:100%;
            }
            

            .number {
                font-size:21px;
                letter-spacing:3px;
                color:$red;
            }

            h3 {
                font-family:$playfair;
                font-size:24px;
                color:$darkest;
                margin-top:16px;
                margin-bottom:21px;
            }

            p {
                font-size:19px;
                color:#757575;
                line-height:28px;
                padding-bottom:30px;
                white-space:normal;
            }
        }
    }
    

    #nextpertise,
    #next-magazine {
        background-color:$red;
        height: 75px;
        width: 75px;
        display:flex;
        justify-content:center;
        align-items:center;
        position:absolute;
        right:0;
        top:50%;
        transform:translateY(-50%);
        z-index:100;
        cursor:pointer;

        img {
            width: 31px;
        }

        @media screen and (max-width:1023px) {
            height: 60px;
            width: 60px;

            img {
              width:25px;  
            }
        }

        @media screen and (max-width:767px) {
            width:44px;
            height:44px;

            img {
                width:18px;
            }
        }
    }

    @media screen and (max-width:767px) {
        max-width:calc(100vw - 25px);
        padding: 25px 0 25px 25px;
        .horizontal-carousel {
            .horizontal-carousel-item {
                margin-right:15px;
            }
        }
    }

    @media screen and (max-width:600px) {
        .horizontal-carousel {
            .horizontal-carousel-item {
                width:300px;
                max-width:300px;
            }
        }
    }
}