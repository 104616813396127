.container {
    width:$containerLargest;
    max-width:calc(100vw - 30px);

    &.logos-grid-wrap {
        width:1110px;
        max-width:calc(100vw - 60px);
        padding-left:0;
        padding-right:0;
    }

    .narrowed {
        padding:80px 66px;
    }

    @media screen and (max-width:1023px) {
        .narrowed {
            padding:40px 0;
        }
    }

    @media screen and (max-width:767px) {
       padding-left:10px;
       padding-right:10px;
       max-width:calc(100vw - 20px);
    }

    
}