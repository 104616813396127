body.about {
    overflow-x:hidden;
    
    .about-text-content {
        padding-bottom:0;
        .about-text-content-row {
            @include leftColRightCol();

            + .about-text-content-row {
                margin-top: 66px;
            }
        }
    } 

    .horizontal-carousel {
        .horizontal-carousel-item {
            box-shadow: 0 0 30px 0 rgba(0,0,0,0.04);
        }
    }
    
    // Team Row
    .about-team-row {
        padding-top:82px;

        .team-category-filters {
            @include categoryFilters();
            padding-bottom:42px;
        }

        .team-loop {
            margin-left:-8px;
            margin-right:-8px;

            .team-member-item {
                width:25%;
                padding:0 8px 60px;
                background-color:#FFF;

                .team-grid-item-text {
                    padding: 24px 30px 0;
                    h4 {
                        font-family:$playfair;
                        font-size:22px;
                        color:$darkest;
                        line-height:30px;
                        margin-bottom: 12px;
                    }
    
                    h5 {
                        font-size:14px;
                        color:$red;
                        letter-spacing:3px;
                        text-transform:uppercase;
                    }
                }
                
            }
        } 

        @media screen and (max-width:1023px) {
            .team-loop {
                margin-left:0;
                margin-right:0;
                padding-left: 22px;
                padding-right: 22px;

                .team-member-item {
                    width:calc(50% - 22px);
                }
            }
        }

        
    }
    // End Team Row

    .awards-row {
        margin-top:40px;

        .section-title {
            @include smallTitleText();
            margin-bottom:45px;
        }

        .awards-loop {
            @include redboxLoop();
        }
    }
}