body.contact {
    .contact-page-columns {
        width: calc(50vw + (#{$containerLargest}/2) - 10px);
        max-width:100%;
        margin-left:auto;
        display:flex;
        justify-content:space-between;
        padding-bottom:95px;

        .form-col {
            width:50%;
            padding-right:155px;
            padding-bottom:82px;

            form {
                
                .input-wrapper {
                    position:relative;

                    input[type="text"],
                    input[type="email"] {
                        width:100%;
                        background-color:#F2F2F2;
                        height:61px;
                        line-height:61px;
                        padding:0 10px;
                        border:none;
                        box-shadow:none;
                        margin-bottom:22px;
                        
                    }

                    input[type="text"],
                    input[type="email"],
                    textarea {
                        &:placeholder {
                            font-size: 19px;
                            color: #757575;
                            line-height: 28px;
                        }

                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            font-size: 19px;
                            color: #757575;
                            line-height: 28px;
                          }
                          &::-moz-placeholder { /* Firefox 19+ */
                            font-size: 19px;
                            color: #757575;
                            line-height: 28px;
                          }
                          &:-ms-input-placeholder { /* IE 10+ */
                            font-size: 19px;
                            color: #757575;
                            line-height: 28px;
                          }
                          &:-moz-placeholder { /* Firefox 18- */
                            font-size: 19px;
                            color: #757575;
                            line-height: 28px;
                          }
                    }

                    ul.errors {
                        position:absolute;
                        bottom:5px;
                        color:$red;
                        font-weight:800;
                    }
    
                    textarea {
                        width:100%;
                        background-color:#F2F2F2;
                        height:245px;
                        max-height:245px;
                        border:none;
                        margin-bottom:22px;
                        padding:10px;
                    }

                }
                

                .submit-wrapper {
                    display:inline-block;
                    position:relative;
                    margin-top:10px;

                    &:after {
                        content: "+";
                        color: $red;
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        font-weight:800;
                    }

                    input[type="submit"] {
                        color:#FFF;
                        background-color: #000;
                        font-size: 13px;
                        text-transform:uppercase;
                        letter-spacing:3.25px;
                        height:49px;
                        line-height:46px;
                        width:184px;
                        border-radius: 24.5px;
                        text-indent:-20px;
                        font-weight:800;
                    }
                }
                
            }

            .message.error {
                padding-top:40px;
                color:$red;
                font-weight:800;
            }

            .message.notice {
                padding-top:40px;
                font-weight:800;
            }
        }

        .right-col {
            width: 50%;
            background-size:cover;
            background-position:center center;
        }
    }

    .contact-red-bar {
        background-color:$red;
        color:#FFF;
        padding:120px 0 100px;
        
        .container{
            display:flex;
            justify-content:space-between;

            .redbar-column {
                width:30%;
                padding-bottom:20px;
                position:relative;

                h2 {
                    font-family:$playfair;
                    font-size: 28px;
                    line-height:31px;
                    margin-bottom:28px;
                }

                p {
                    font-size:18px;
                    line-height:26px;
                    margin-bottom:35px;
                }

                a {
                    color:#FFF;
                    border-bottom:2px solid #FFF;
                    display:inline-block;
                    padding-bottom:2px;
                    font-family:$sourceSansPro;
                    letter-spacing:1px;
                    text-transform:uppercase;
                    position:absolute;
                    bottom:0;
                    left:0;
                }
            }
        }
    }

    @media screen and (max-width:1310px) {
       .contact-page-columns {
           width:100%;
           padding-left:30px;

           .form-col {
               padding-right:80px;
           }
       } 
    }

    @media screen and (max-width:1023px) {
        .contact-page-columns {
            padding:0 30px;
            .form-col {
              padding-right:0;  
            }

            .bg-img-col {
                display:none;
            }
        }

        .contact-red-bar {
            padding:40px 30px;

            .container {
                flex-direction:column;
                width:100%;
                padding:0;

    
                .redbar-column {
                    a {
                        position:static;
                    }

                    + .redbar-column {
                        margin-top:30px;
                    }
                }
            }
        }
    }

    @media screen and (max-width:767px) {
        .contact-page-columns {
            padding:0 20px;

            .form-col {
                width:100%;
                max-width:440px;
            }
        }

        .contact-red-bar {
            padding:30px 20px;
        }
    }
}