// Colors
$red: #E20519;
$darkest: #111111;

// Fonts
$sourceSansPro: 'Source Sans Pro', sans-serif;
$playfair: 'Playfair Display', serif;

// Container Sizing
$containerLargest: 1280px;
