.site-header {
    width:100%;
    padding:40px 30px;
    box-sizing:border-box;

        .logo-container {
            max-width: 273px;

            img {
                max-width:100%;
            }
        }

        .hamburger {
            background: $red;
            position:fixed;
            right:30px;
            top:27px;
            width:42px;
            height:42px;
            display:block;
            border-radius:100%;
            border:none;
            z-index:9999;

            .hamburger__inner {
                .center-line {
                    background-color:#FFF;
                    width: 16px;
                    position:absolute;
                    right:0;
                    top:50%;
                    transform:translateY(-50%);
                    height:2px;
                    display:block;
                }
            } 
            
            &.is-active {
                

                .hamburger__inner {
                    &:before,
                    &:after{
                        background-color:#FFF;
                    }
                    

                    .center-line {
                        opacity:0;
                    }

                }
            }
        }

    @media screen and (max-width:767px) {
        padding: 19px 20px 18px;
        position:fixed;
        background-color:#FFF;
        top:0;
        left:0;
        width:100%;
        z-index:101;

        

        .logo-container {
            max-width:158px;
        }

        .hamburger {
            right:10px;
            top:5px;
            background-color:transparent;

            .hamburger__inner {
                &:before,
                &:after,
                .center-line {
                    background-color:$red;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    body:not(.home-body) {
        padding-top:53px;
    }

    &.open {
        .site-header {
            position:absolute;
            opacity:1;
            z-index:99999;
            background-color:transparent;

            .logo-container {
                display:none;
            }

            .hamburger {
                z-index:999999;
            }
        }
    }
}