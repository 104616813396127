// Work Listing Details
.work.listing {

    .gutter-sizer {
        width:12.4%;
    }

    .work-category-filters {
        @include categoryFilters();
    }
    .work-listing-loop {
        opacity:0;
        transition:1s ease-in-out;
        margin-top:40px;

        &.fade-in {
            opacity:1;
        }

        .stamp {
            position: absolute;

            &.stamp1 {
                right:0;
                top: 0;
                width:43.8%;
                height: 76px;
              }
          }
          
        .work-listing-item {
            @include projectListing();
            margin-bottom:190px;

            &.last-item {
                margin-bottom:0;
            }

            &.hidden {visibility:hidden;
                width:0px!important;
                height:0px!important;
                margin:0px!important;
                padding:0px!important;
                }
    
    
            .project-number {
                font-weight:800;
                font-size: 140px;
                letter-spacing:10px;
                position:absolute;
                right:0;
                top:0;
                transform:translate(25%,-70%);
                color:#F8F8F8;
            }
    
    
            .plus {
                color:$red;
            }
    
         @media screen and (max-width:767px) {
             margin-bottom:120px;

             &.screen-right-listing {
                 margin-top:0;
             }
         }   
        }

    }

    .more-projects-button-wrap {
        margin-top:-176px;
        
        .animated-button {
            margin-top:80px;
        }
        @media screen and (max-width:767px) {
            margin-top:-40px;

            .animated-button {
                margin-top:0;
            }
        }
    }

    @media screen and (max-width:991px) {
        .gutter-sizer {
            width:60px;
        }
    }

    @media screen and (max-width:767px) {
        .work-listing-loop { 
            margin-top:0;
        }
        .gutter-sizer {
            width:0;
        }
    }

}