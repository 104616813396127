#modal {
    position:fixed;
    top:0;
    left:0;
    min-width:100vw;
    min-height:100vh;
    background-color: $darkest;
    z-index:999999999;
    display:none;
    overflow: auto;

    .close-modal {
        position:fixed;
        display:flex;
        align-items: center;
        right: 35px;
        top:30px;
        font-size: 14px;
        line-height:26px;
        font-weight:800;
        text-transform:uppercase;
        letter-spacing:3.5px;
        color:#FFF;
        z-index:1;

        &:hover,
        &:focus,
        &:active {
            color:#FFF;
        }

        img {
            margin-left:12px;
        }
    }
   

    .modal-interior-wrapper {
        max-width:calc(100vw - 60px);
        width:912px;
        position:absolute;
        left:50%;
        top:0;
        transform:translate(-50%, 0);
        color:#FFF;
        padding:30px 0;

        .lightbox-detail-image {
            position:absolute;
            width:auto;
            height:auto;
            min-width:0;
            min-height:0;
            top:30px;
            max-width:calc(100vw - 300px);
            max-height:calc(100vh - 60px);
            left:50%;
            transform:translateX(-50%);
        }
        
        iframe {
            width:100%;
            height:calc(100vh - 160px);
        }

        // Team Related Content
        .name {
            @include headerText(#FFF);
            margin-top:100px;
            margin-bottom:7px;
        }

        .job-title {
            font-size:16px;
            line-height:65px;
            text-transform:uppercase;
            color:$red;
            letter-spacing:3px;
            position:relative;

            &:before {
                content:'';
                width: 32px;
                position:absolute;
                left:-40px;
                top:32px;
                height:1px;
                display:block;
                background-color:$red;
            }
        }

        .modal-profile-img {
            display:block;
            margin: 35px 0 35px;;
        }

        .tm-section-header {
            font-size: 24px;
            line-height:32px;
            font-family:$playfair;
            margin-bottom:13px;
        }

        p {
            color: #8F8E8E;
            line-height:28px;
            font-size:19px;
        }

        .experience-column {
            width:80%;
        }

        .team-two-col-row {
            margin-top:38px;
            display:flex;
            justify-content:space-between;
            .left-col,
            .right-col {
                width:43.87%;
            }
        }

        .view-all-team-btn-wrap {
            text-align:center;
            margin-top:100px;
            margin-bottom:60px;

            .animated-button {
                color:#FFF;
                
                &:before { 
                    background-color: #DCDCDC;
                    opacity:0.25;
                }
            }
        }
    }
    // End Team Related Content

    @media screen and (max-width:1023px) {
        .modal-interior-wrapper {
            .job-title {
                &:before {
                    display:none;
                }
            }

            .experience-column {
                width:100%;
            }

            .team-two-col-row {
                .left-col,
                .right-col {
                    width:calc(50% - 15px);
                }
            }
        }
    }

    @media screen and (max-width:767px) {
        .modal-interior-wrapper {
            .team-two-col-row {
                flex-direction:column;
                margin-top:0;

                .left-col,
                .right-col {
                    width:100%;
                    margin-top:30px;
                }
            }
        }
    }
}