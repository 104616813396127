
@mixin smallTitleText() {
    font-size:14px;
    letter-spacing:5px;
    line-height:24px;
    text-transform:uppercase;
}

@mixin headerText($color: $darkest, $fontWeight: 400) {
    font-size: 50px;
    line-height: 65px;
    font-family: $playfair;
    color: $color;
    font-weight: $fontWeight;
}

@mixin projectListing() {
    width:43.8%;
    max-height:none;
    position:relative;
    overflow:visible;

    .project-link {
        font-size:18px;
        line-height:26px;
        letter-spacing:2.25px;
        text-transform:uppercase;
        color:#333;
        padding:16px 24px 15px;
        background-color:#FFF;
        z-index:6;
        position:absolute;
        bottom:24px;
        left:-18px;
        display:flex;
        flex-direction:row-reverse;
        align-items:center;
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);

        img {
            width:24px;
            display:block;
            margin-right:32px;
        }

        .plus {
            margin-left:32px;
            margin-right:0;
        }
    }

    .project-listing-img {
        width:100%;
        height:1;
        padding-bottom: 61.8%;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center center;
        transform:translateZ(0);
    }

    &.screen-right-listing {
        margin-top:80px;
    }

    @media screen and (max-width:991px) {
        width:calc(50% - 30px);
    }

    @media screen and (max-width:767px) {
        width:100%;
    }
}

@mixin leftColRightCol() {
    display:flex;
    justify-content:space-between;

    .left-col {
        h3 {
            @include smallTitleText();
        }
    }
    .right-col {
        width:70%;
        max-width:70%;

        h2 {
            font-family:$playfair;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 30px;
        }

        p {
            color:#757575;
            font-size: 19px;
            line-height: 28px;
        }
    }

    @media screen and (max-width:1023px) {
        flex-direction:column;

        .left-col,
        .right-col {
            width:100%;
            max-width:100%;
        }
    }
}

@mixin categoryFilters() {
    display:flex;
    padding-bottom:100px;

    h3 {
        @include smallTitleText();
    }

    .filter-placeholder {
        display:none;
    }

    ul {
        display:flex;
        flex-wrap:wrap;

        li {
            margin-left:45px;

            a {
                line-height:24px;
                font-size:19px;
                font-weight:600;
                padding:0 5px;
                position:relative;

                &:after {
                    opacity:0;
                    content:'';
                    display:block;
                    transition:opacity 1s ease-out;
                }
            }
            @media screen and (min-width:1024px) {
                &.active {
                    a {
                        color: $red;

                        &:after {
                            display:block !important;
                            content:'';
                            background-color:$red;
                            opacity:0.17;
                            width:100%;
                            height:5px;
                            position:absolute;
                            bottom:3px;
                            left:50%;
                            transform:translateX(-50%);
                        }
                    }  
                }
            }
        }
    }

    @media screen and (max-width:1023px) {
        display:block;
        position:relative;
        h3 {

        }
        .filter-placeholder {
            display:block;
            width:100%;
            border:1px solid $darkest;
            height: 61px;
            line-height:61px;
            position:relative;
            padding:0 20px;
            cursor:pointer; 

            &:after {
                content:'+';
                color:$red;
                position:absolute;
                right:20px;
                top:50%;
                transform:translateY(-50%);
                font-size:18px;
            }

            &.filters-open {
                &:after {
                    content:'-';
                    font-size:25px;
                    padding-bottom:4px;
                }
            }
        }

        ul {
            display:none;
            position:absolute;
            width:100%;
            background-color:#FFF;
            z-index:10;
            padding:10px 20px;
            border:1px solid $darkest;
            border-top:none;

            &.visible-filters {
                display:block;
            }

            li {
                display:block;
                width:100%;
                margin:5px 0;

                a {
                    padding:5px 0;
                    width:100%;
                }
            }
        }
    }

    @media screen and (max-width:767px) {
        padding-bottom:50px;
    }
}

@mixin redboxLoop() {
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;

    .redbox-item { 
        width:22.7%;
        perspective: 1000px;
        height:235px;
        display:block;
        
        &:first-child {
            margin-top:0;
        }

        &:nth-child(n+5) {
            margin-top:34px;
        }

        &:hover .redbox-inner {
            transform: rotateY(180deg);
        }

        .redbox-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;

            .redbox-front,
            .redbox-back {
                position: absolute;
                width: 100%;
                height: 100%;
                backface-visibility: hidden;
            }

            .redbox-front {
                background-color:#F8F8F8;
                display:flex;
                align-items:center;
                justify-content:center;

                img {
                    max-width: 175px;
                    max-height:134px;
                }
            }

            .redbox-back {
                background-color:$red;
                transform: rotateY(180deg);
                padding:15px;
                display:flex;
                flex-direction:column;
                align-items:center;
                justify-content:center;

                span {
                    color:#FFF;
                    display:block;
                    text-align:center;
                    margin-top:auto;
                    letter-spacing:3px;
                    text-transform:uppercase;
                    padding-top:10px;
                }

                img {
                    max-width: 62px;
                    max-height: 62px;
                    display:block;
                    margin:auto 0 0 auto;
                    filter: brightness(150%);
                }
            }
        }
    }

    @media screen and (max-width:1023px) {
        .redbox-item { 
            width:calc(50% - 15px);

            &:nth-child(n+3) {
                margin-top:30px;
            }
        }
    }

    @media screen and (max-width:767px) {
        .redbox-item {
            margin-bottom:0;
        }
    }
}