.online-publications-row {
    .online-publications-section-text {
        @include leftColRightCol();
    }

    .online-publications-loop {
        padding-top:42px;

        @include redboxLoop();

        .redbox-item.online-publication-item {

            &.hidden {
                display:none;
            }

            .redbox-inner .redbox-front img {
                max-width:180px;
            }
        }
    }

    .button-wrapper {
        padding-top:80px;
        padding-bottom: 100px;
    }
}