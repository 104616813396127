.home-body {
    
    .side-carousel-status-bar {
        width: 3px;
        height: 389px;
        position:absolute;
        z-index:200;
        top:50%;
        left: 64px;
        transform:translateY(-50%);
        overflow:hidden;

        .interior {
            background-color:#FFF;
            position:relative;
            width:100%;
            height:100%;

            .red-strip {
                background-color:$red;
                width:100%;
                height:0;
                position:absolute;
                top:0;

                

                &.grow {
                    animation-name: statusbar;
                    animation-duration: 5000ms;
                    animation-iteration-count: 1;
                    animation-timing-function: linear; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
                    animation-fill-mode: forwards; /* or: backwards, both, none */
                    animation-delay: 0; /* or: Xms */
                }

                &.initgrow {
                    animation-name: statusbar;
                    animation-duration: 3800ms; 
                    animation-iteration-count: 1;
                    animation-timing-function: linear; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
                    animation-fill-mode: forwards; /* or: backwards, both, none */
                    animation-delay: 0; /* or: Xms */
                }
            }
        } 
    }

    .slider-horizontal {
     padding-left:0;
     padding-right:0;
    

    .slider__image {
        width:100vw;
        flex:1;
    }
        .slider__image {
            .swiper-wrapper {
                .swiper-slide {
                    .projet-slider-images {
                        .interior {
                            max-height:100%;
                            height:100%;
                            position:relative;

                            .project-slider-image {
                                background-size:cover;
                                background-position:center center;
                                position:absolute;
                                top:auto;
                                min-width:100%;
                                min-height:100vh;
                            }
                        }
                    }
                    
                }
            }
            
        }
    }

    #homepage-bottom-bar {
        position:fixed;
        bottom:0;
        right:0;
        z-index:30;
        display:flex;
        justify-content:flex-end;
        flex-wrap:wrap;

        .button-wrap {
            background-color:#FFF;
            display:flex;
            align-items:center;

            a {
                min-width:200px;
                padding:6px 59px 6px 40px;

                &:after {
                    right:34px;
                }
            }
        }

        .control-slider {
            display:flex;

            .swiper-button-prev,
            .swiper-button-next {
               background-color:$red;
               width:87px;
               height:52px;
               display:flex;
               align-items:center;
               justify-content:center;
               position:static;
            }

            .swiper-button-prev {
                background-color:#CC0517 !important;
            }

            .swiper-button-next {
                img {
                    transform:rotate(90deg);
                }
            }
            .swiper-button-prev {
                img {
                    transform:rotate(-90deg);
                }
            }
        }

        .home-contact-items {
            display:flex;
            background-color:#1F1E1D;
            padding:0 40px;
            span {
                font-size:14px;
                letter-spacing:1px;
                color:#FFF;
                height:100%;
                display:flex;
                align-items:center;


                a {
                    color:#FFF;
                }
    
                + span {
                    padding-left:76px;
                }
            }
        }
    }

    @media screen and (max-width:855px) {
        .side-carousel-status-bar {
            transform:rotate(-90deg);
            transform-origin:top left;
            position:fixed;
            top:calc(100vh - 80px);
            left:0;
            height:100vw;
        }

        #homepage-bottom-bar {
            width:100%;

            .home-contact-items {
                order:3;
                width:100%;
                min-width:100%;
                height:40px;
                justify-content:space-between;
                span{  
                    font-size:12px;

                    + span {
                        padding-left:37px;
                    }
                }
            }

            .button-wrap {
                order:1;
                flex:1;
                span {
                    display:block;
                    width:100%;

                    a {
                        width:100%;
                        display:block;
                        text-align:center;
                        font-size:12px;
                        letter-spacing:2.3px;
                        &:after {
                            right:auto;
                            left:calc(50% + 50px);
                        }
                    }
                }
                
            }

            .control-slider {
                order:2;
                width:40%;

                .swiper-button-prev,
                .swiper-button-next {
                    height:40px;
                    width:50%;

                    img {
                        width:7px;
                    }
                }
            }
        }
    }
}

@keyframes statusbar {
    0%   { height: 0; }
    100% { height:100%; }
}