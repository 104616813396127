.vertical-slider-wrapper {
    width:calc(50vw + (#{$containerLargest}/2));
    max-width:calc(100vw - 25px);
    position:relative;
        
    .vertical-slider{
        &:before {
            z-index:101;
        }
        &:after {
        z-index:100;  
        }
        .slider-slide {
            padding-bottom:41.42%;
            width:100%;
            background-size:cover;
            background-position:center center;
        }
    }

    .carousel-nav {
        position:absolute;
        display:inline-flex;
        z-index:100;
        right:0;
        bottom:0;

        a {
            width:88px;
            height:88px;
            background-color:$red !important;
            transform-origin:center center;
            display:block;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }

    @media screen and (max-width:1023px) {
        .carousel-nav {
            a {
                width: 60px;
                height: 60px;
            }
        }
    }
    @media screen and (max-width:767px) {
        .carousel-nav {
            a {
                width:44px;
                height:44px;

                img {
                    width:8px;
                }

                &#previous-slide {
                    display:none;
                }
            }
        }
    }
}
