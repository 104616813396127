// Work Detail Page Styles
body.work.detail {

    .fixed-project-detail-info {
        position:fixed;
        transform:rotate(90deg) translate(100%, 0);
        right:0;
        top: 160px;
        transform-origin:100% 50%;
        padding-top:100px;
        opacity:1;
        transition:opacity 0.1s ease-out;

        &.hide{
            opacity:0;
        }

        span {
            @include smallTitleText();
            display:inline-block;
            position:relative;
            
            + span {

                padding-left:60px;

                &:before {
                    content:'';
                    display:block;
                    width:24px;
                    height:1px;
                    background-color:$darkest;
                    position:absolute;
                    left:12px;
                    top:50%;
                    transform:translateY(-50%);
                }
            }  
        }
    }

    .page-header {
        font-weight:600;
    }

    .project-detail-padded-wrap {
        padding-left:60px;
        padding-right:60px;
        width:1370px;
        max-width:100%;
        margin-left:auto;
        margin-right:auto;
    }
    .project-detail-banner {
        height:600px;
        background-size:cover;
        background-position:center center;
        width:100%;
    }

    .project-details-descrition-row {
        @include leftColRightCol();
    }

    .project-detail-images-matrix {
        .detail-images-row {
            display:flex;

            &.two-col-row {
                justify-content:space-between;
                .detail-images-col {
                    width:calc(50% - 8px);
                    padding-bottom:50%;
                }
                
            }

            &.single-col-row {
                
                .detail-images-col {
                    padding-bottom:50%;
                    width:100%;
                }
            }

            .detail-images-col  {
                background-size:cover;
                background-position:center center;
                cursor:pointer;
            }

            + .detail-images-row  {
                padding-top:16px;
            }
        }
    }

    .project-team-row {
            padding-bottom:0;
            display:flex;

            .left-col {
                width:25%;

                h3 {
                    @include smallTitleText();
                }
            }

            .right-col {
                width:75%;
                display:flex;
                flex-wrap:wrap;
                justify-content:space-between;

                .team-member-col {
                    width:calc(33.3333% - 10px);
                    
                    &.two-col-item {
                        width:calc(50% - 10px);
                    }
                }
            }
    }// End .project-team-row

    .project-press-row {
            display:flex;

            .left-col {
                width:25%;

                h3 {
                    @include smallTitleText();
                }
            }

            .right-col {
                width:75%;
                display:flex;
                flex-wrap:wrap;
                justify-content:space-between;

                .publication-col {
                    width:calc(33.3333% - 10px);
                    
                    &.two-col-item {
                        width:calc(50% - 10px);
                    }
                }
            }
    }

    .project-siblings {


        h3 {
            @include headerText();
            float:right;
            width:43.8%;
            margin-top:95px;
        }

        .project-sibling-item {
            @include projectListing();
            float:left;

            &.next-project {
                float:right;
            }
        }
    }

    @media screen and (max-width: 1450px) {
        .fixed-project-detail-info {
            padding-top:64px;
            top:179px;
        }
    }


    @media screen and (max-width: 1023px) {
        .fixed-project-detail-info {
            display:none;
        }

        .project-detail-padded-wrap {
            padding-left:30px;
            padding-right:30px;

            .project-detail-banner {
                height:58.6vw;
            }
        }

        .project-team-row,
        .project-press-row {
            display:block;

            .left-col {
                padding-bottom:30px;
                width:100%;
            }

            .right-col {
                width:100%;
                .team-member-col {
                    width:50%;
                }
            }
        }
    }

    @media screen and (max-width:767px) {
        .project-detail-padded-wrap {
            padding-left:20px;
            padding-right:20px;
        }
    }
    
    
}