body.press {
   .horizontal-carousel-wrapper {
       background-color:transparent;
       padding:0;

       .horizontal-carousel-item  {
        padding:0;
        max-width:none;
        width:auto;
        max-height:400px;
        height:400px;
        margin-right:0;

        img {
            width:auto;
            max-height:100%;
        }
       }
   }

   @media screen and (max-width: 1024px) {
    .horizontal-carousel-wrapper {
        .horizontal-carousel-item {
            max-height:300px;
            height:300px;
        }
    }
   }

   @media screen and (max-width:767px) {
       .horizontal-carousel-wrapper {
           .horizontal-carousel-item {
               margin-right:0;
           }
       }
   }
   
}