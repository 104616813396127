.home-body {
    opacity:0; 
    background-color:#000;
    transition:opacity 2s ease-in-out;

    &.fade-in-content {
        opacity:1 !important;
    }
    
    .site-header {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        z-index:1000;

        

        .logo-container {
            position:relative;
            &:before {
                content:'';
                display:block;
                width:632px;
                height:632px;
                top:50%;
                left:50%;
                position:absolute;
                transform:translate(-50%, -50%);
                z-index:-1;
                background-image: radial-gradient(48% 57%,#fff 0,hsla(0,0%,100%,0) 90%);
            }
        }
    }

    &.open {
        .site-header {
            position:absolute;
            opacity:1;
            z-index:99999;

            .logo-container {
                display:none;
            }

            .hamburger {
                z-index:999999;
            }
        }
    }

    @media screen and (max-width:767px) {

        &.open {
            .site-header {
                background-color:transparent;
            }
        }

        .site-header {
         background-color:#FFF;
         position:absolute;

         .logo-container {
             &:before {
                 display:none;
             }
         }
        }
    }
}