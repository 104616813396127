.menu {
    background:$red;
    padding:40px 30px;
    color:#FFF;

    .menu-logo {
        align-self:flex-start;
        img {
            width:273px;
        }
        
    }
    .menu-inner {
       

        .menu-inner-columns-wrap {
            display:flex;
            justify-content:center;


            .menu-left-col {
                flex-direction:row;
                position:relative;
                padding:0 55px;
                min-width:50%;

                .title-tab {
                    position:absolute;
                    left:-60px;
                    top:0;
                    transform:rotate(-90deg) translate(-50%, -100%);
                    transition:opacity .6s cubic-bezier(.72,.16,.345,.875) .64s !important;

                    &:before {
                        background-color:#FFF;
                    }
                }

                .menu-list {
                    li {
                        .count {
                            font-size:15px;
                            letter-spacing:3px;
                            vertical-align:top;
                            padding-top:10px;
                            padding-right:20px;
                            display:inline-block;
                            transition:opacity 0.5s ease-in-out;

                            &.inactive {
                                opacity:0.2;
                            }
                        }
                        .menu-list__item {
                            vertical-align:top;
                            font-family:$playfair;
                            font-size:60px;
                            font-weight:bold;
                        }

                        + li {
                            margin-top:30px;
                        }
                    }
                    
                }
            }

            .menu-right-col {
                font-size:16px;
                line-height:24px;
                letter-spacing:2px;
                display:flex;
                flex-direction:column;
                justify-content:center;
                text-transform:uppercase;

                a {
                    color:#FFF;
                    display:block;
                }

                .contact-address {
                    margin:24px 0 50px; 

                }

                .social-media-links {
                    display:flex;
                    align-items:flex-end;

                    a {
                        text-decoration:none;
                        vertical-align:bottom;
                        margin-right:22px;

                        i {
                            color:#FFF;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:1023px) {
        .menu-inner {
            padding-left:0;
            padding-right:0;

            .menu-inner-columns-wrap {
                flex-direction:column;
                align-items:center;
                .menu-left-col,
                .menu-right-col {
                    min-width:100%;
                }
                .menu-left-col {
                    padding:0 0 0 55px;

                    .title-tab {
                        left:-30px;
                    }
                }

                .menu-right-col {
                    padding:30px 0 0 55px;
                }
            }
        }
    }
    
    @media screen and (max-width:767px) {
        padding:19px 20px;
        
        .menu-logo {
            img {
                width: 158px;
            }
        }
        .menu-inner {
            .menu-inner-columns-wrap {
                .menu-left-col {
                    padding-left:30px;

                    .title-tab {
                        // left:-28px;
                        // top:-5px;
                        display:none;
                    }
                }
            }
        }
    }
}

.ef-background { 
    background-color:$red;
}