.video-section {
    width: calc(50vw + (#{$containerLargest}/2) - 10px);
    max-width:100%;
    margin-left:auto;
    background-color: #F8F8F8;
    padding:61px 65px 66px;
    margin-top:115px;
    margin-bottom:80px;

    h2 {
        font-family:$playfair;
        font-size:24px;
        margin-bottom: 24px;
        color:$darkest;
    }

    .video-section-interior {
        width:1109px;
        max-width:100%;
        display:flex;
        flex-wrap:wrap;
        margin-left:-15px;
        margin-right:-15px;
        
        .responsive-video-container {
            position: relative;
            padding-left:15px;
            padding-right:15px;
            a {
                display:block;
                cursor:pointer;
            }
            &.half-row {
                width:50%;

                &:nth-child(n+3) {
                    margin-top:30px;
                }
            }

            &.third-row {
                width:33.3333333%;

                &:nth-child(n+4) {
                    margin-top:30px;
                }
            }
            .video-play-btn {
                position:absolute;
                z-index:1;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
            }            
        }
    }

        

@media screen and (max-width: 1380px) {
    width:100%;
    padding:61px 0 65px 30px;

    .video-section-interior {
        width:100%;
    }
}

@media screen and (max-width:1023px) {
    .video-section-interior {

    }
}

@media screen and (max-width:767px) {
    .video-section-interior {
        .responsive-video-container {
            &.half-row,
            &.third-row {
                width:100%;

                &:nth-child(n+2) {
                    margin-top:30px;
                }
            }
        }
    }
}
        
}