.cleaner {
    clear:both;
}

.hidden-preloaded-images {
    display:none !important;
}

.title-tab-wrap {
    padding:0 50px 6px;

    .title-tab {
        margin-left:-66px;
    }
}
.title-tab {
    @include smallTitleText();
    padding-left:34px;
    position:relative;

    &:before {
        content:'';
        display:block;
        width:24px;
        height:1px;
        background-color:$darkest;
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
    }

    @media screen and (max-width:1380px) {
        padding-left:60px;

        &:before {
            left:30px;
        }
    }
}

.page-header {
    @include headerText();
    margin-bottom:27px;
}

.general-content-wrapper {
    padding-bottom: 100px;
}

.centerer-wrapper {
    text-align:center;
}

.animated-button {
    font-size:14px;
    letter-spacing:3.5px;
    line-height:26px;
    font-weight:bold;
    text-transform:uppercase;
    position:relative;
    padding-left:18px;
    padding-right:60px;
    padding-top:6px;
    padding-bottom:8px;
    display:inline-block;

    &:before {
        content:'';
        width: 40px;
        height: 40px;
        border-radius:20px;
        position:absolute;
        left:0;
        top:0;
        display:block;
        background-color: #ececec;
        z-index:-1;
        animation: buttonUnhover 1s ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &:after {
        content:'+';
        color:$red;
        position:absolute;
        right:12px;
        top:50%;
        transform:translateY(-50%);
        display:block;
    }

    &:hover {
        &:before {
           animation: buttonHover 1s ease-in-out;
           animation-iteration-count: 1;
           animation-fill-mode: forwards;
        }
    }

    @keyframes buttonHover {
        0% { width:40px; ; left:0;}
        45% { width: 100%; left:0;}
        46% { width: 100%; left:0; right:0;}
        47% { width: 100%; left:auto; right:0;}
        100% { width:40px; right:0; left:auto;}
      }

      @keyframes buttonUnhover {
        0% { width:40px; right:0; left:auto;}
        45% { width: 100%; left:auto; right:0;}
        46% { width: 100%; left:0; right:0;}
        47% { width: 100%; left:0;}
        100% { width:40px; ; left:0;}
      }

}